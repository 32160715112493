<template>
   
          <div class="bloquemain">
        <img class="imgprox" src="../assets/prox.png" alt="Proximamente">
        <div class="filauno">
          <div class="coluno-f1">
            <div class="bloque1 blologolaa">
              <img src="../assets/logolaa.png" alt="LABORATORIO DE ALIMENTOS Y AGUA DE SONORA">
              <div class="bloqueredes">
                <a class="afb1" href="https://www.facebook.com/LAASONMX"><img src="../assets/fblabs.png" alt=""></a>
              </div>
            </div>
            <div class="bloque2">
              <h2>LABORATORIO</h2>
              <h2>DE ALIMENTOS Y AGUAS</h2>
              <h2 class="tit3-br">DE SONORA S.A. DE C.V.</h2>
              <p>Garmendia N°80,</p>
              <p>Col.San Benito C.P. 83190</p>
              <p class="txt3-br">Hermosillo, Sonora.</p>
              <div class="bloquetele">
                <div>
                  <img src="../assets/itelabs.png" alt="">
                </div>
                <p><a href="tel:6622132302">(662) 213.23.02</a> y <a href="tel:6623130650">(662) 313.06.50</a></p>
              </div>
              <div class="bloquetele">
                <div>
                  <img src="../assets/iemalabs.png" alt="">
                </div>
                <p><a href="mailto:direccion@laason.mx">direccion@laason.mx</a></p>
              </div>
              <a class="btncerti" :href="`${publicPath}certificadolaason.pdf`" target="_blank">Ver certificado</a>
           
            </div>
          </div>
          <div class="coluno-f1">
            <div class="bloque1">
              <img src="../assets/logobalabs.png" alt="LABORATORIO CLÍNICO BARRAGÁN">
              <div class="bloqueredes">
                <a class="afb" href="https://www.facebook.com/LaboratorioBarragan"><img src="../assets/fblabs.png" alt=""></a>
                <a href="https://www.instagram.com/laboratoriobarragan"><img src="../assets/iglabs.png" alt=""></a>
              </div>
            </div>
            <div class="bloque2">
              <h2>LABORATORIO</h2>
              <h2>CLÍNICO</h2>
              <h2 class="tit3-br">BARRAGÁN</h2>
              <p>Garmendia N°80,</p>
              <p>Col.San Benito C.P. 83190</p>
              <p class="txt3-br">Hermosillo, Sonora.</p>
              <div class="bloquetele">
                <div>
                  <img src="../assets/itelabs.png" alt="">
                </div>
                <p><a href="tel:6622132302">(662) 213.23.02</a> y <a href="tel:6623130650">(662) 313.06.50</a></p>
              </div>
              <div class="bloquetele">
                <div>
                  <img src="../assets/iemalabs.png" alt="">
                </div>
                <p><a href="mailto:barragan@laason.mx">barragan@laason.mx</a></p>
              </div>
              <a  class="btncerti" :href="`${publicPath}certificadobarragan.pdf`" target="_blank">Ver certificado</a>
            
            </div>
          </div>
        </div>
     
     
      </div>
  
</template>



<script>

export default {
  name: 'Proximamente',

    data () {
  return {
    publicPath: process.env.BASE_URL
  }
}
}




</script>
<style scoped>
   @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

@media (min-width: 1024px) {
    .bloquemain {
  text-align: center;
  padding-top: 5vw;
}
img.imgprox {
    width: 32.395833333333336VW;
}
.filauno {
    display: flex;
    padding-top: 6vw;
    width: 75%;
    margin: auto;
}
.coluno-f1 {
    width: 50%;
    text-align: left;
    display: flex;
}
.blologolaa img {
   margin-top: -2.5vw;
    margin-right: 2vw;
}

.afb1 img{
 margin-top: -0.5vw;
}
.bloqueredes {
    text-align: center;
    padding-top: 1vw;
}
.bloque2 {
  padding-top: 3vw;
  padding-left: 1vw;
}
.bloque2 h2 {
    font-size: 1.0416666666666665VW;
    color: #0A4CA1;
    font-family: 'Roboto';
    font-weight: 700;
    text-align: left;
    line-height: 1.4166666666666665VW;
    margin: 0;
    font-family: 'Roboto', sans-serif;
}
h2.tit3-br {
    padding-bottom: 2.5vw;
}
.bloque2 p {
    font-size: 0.7291666666666666VW;
    line-height: 1.0755208333333333VW;
    margin-bottom: 0;
    font-family: 'Roboto', sans-serif;
    margin-top: 0;
}
.bloque2 a {
    text-decoration: none;
    color: #000;
    cursor: pointer;
}
.bloquetele {
    display: flex;
    align-items: center;
    margin-bottom: .5vw;
}
.txt3-br {
  padding-bottom: 1vw;
}
.bloquetele img {
    margin-right: 1vw;
}
.bloqueredes .afb:nth-child(1) {
    margin-right: 1vw;
}
.coluno-f1 a.btncerti {
  background: linear-gradient(85.48deg, #6FBA91 4.15%, #76DFA6 139.07%) !important;
  border-radius: 30px !important;
  color: #fff !important;
  padding: 1vw 3vw !important;
  font-size: 0.75VW !important;
  text-decoration: none !important;
  position: relative !important;
  top: 2vw !important;
}
}

@media (max-width: 1023px) {

.bloquemain {
    text-align: center;
    padding-top: 15vw;
}
img.imgprox {
    width: 62.56038647342995VW;
    height: auto;
}
.bloque2 h2 {
    font-size: 5.314009661835748VW;
    line-height: 7.227053140096619VW;
    color: #0A4CA1;
    font-family: 'Roboto';
    font-weight: 700;
    margin: 0;
    font-family: 'Roboto', sans-serif;
}
h2.tit3-br {
    padding-bottom: 2.5vw;
}
.bloque2 p {
    font-size: 3.3816425120772946VW;
    line-height: 4.987922705314009VW;
    margin-bottom: 0;
    font-family: 'Roboto', sans-serif;
    margin-top: 0;
}
.bloque2 a {
    text-decoration: none;
    color: #000;
    cursor: pointer;
}
.bloque1 img {
    width: 28.985507246376812VW;
}
.bloqueredes img {
    width: 8.695652173913043VW;
}
.coluno-f1 {
    padding-top: 12vw;
}
.bloqueredes {
    margin-top: 3vw;
    margin-bottom: 5vw;
}
.bloquetele {
    display: flex;
    width: 63%;
    margin: auto;
    margin-top: 5vw;
}
.bloquetele img {
    margin-right: 4vw;
}
.bloque2 {
    margin-bottom: 5vw;
}
.coluno-f1 button.btncerti {
  background: linear-gradient(85.48deg, #6FBA91 4.15%, #76DFA6 139.07%) !important;
  border-radius: 60px !important;
  color: #fff !important;
  padding: 6vw 12vw !important;
  font-size: 2.46875VW !important;
  text-decoration: none !important;
  position: relative !important;
  top: 4vw !important;
}
}


</style>
